.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
}

.swiper-slide {
    background-size: cover;
    background-position: center;
}

.mySwiper2 {
    height: 80%;
    width: 100%;
}

.mySwiper {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
}

.mySwiper .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 1;
}

.mySwiper .swiper-slide-thumb-active {
    opacity: 0.6;
}

// lot slider

.lot-swiper {
    position: relative;

    .lot-swiper-inner {
        position: absolute;
        bottom: 1%;
        padding: 10px;
        height: 27%;

        @media (min-width: 576px) {
            height: 20%;
            bottom: 2%;
        }

        .swiper-slide {
            .lot-swiper-img {
                width: 100%;
                position: relative;

                img {
                    height: 50px;
                    border-radius: 4px 0 4px 4px;

                    @media (min-width: 576px) {
                        height: 60px;
                        border-radius: 8px 0 8px 8px;
                    }
                }
            }
        }
    }

    .mySwiper2 {
        height: 100%;

        .swiper-slide {
            border-radius: 6px;

            @media (min-width: 576px) {
                border-radius: 13px;
            }

            img {
                border-radius: 6px;
                cursor: pointer;

                @media (min-width: 576px) {
                    border-radius: 12px;
                }
            }
        }
    }
}

.lot-popup {
    --tw-bg-opacity: 0.9 !important;

    .lot-img-popup {
        width: 100% !important;

        .lot-img-popup-inner {
            height: 100%;
            padding: 0;
            position: relative;
            background-color: transparent;

            @media (min-width: 576px) {
                height: 500px;
            }
            @media (min-width: 1024px) {
                height: 600px;
            }
            @media (min-width: 1360px) {
                height: 750px;
            }

            .mySwiper {
                padding: 0 10px;
                position: absolute;
                bottom: 2%;
                height: 50px;

                @media (min-width: 480px) {
                    height: 70x;
                }
                @media (min-width: 1024px) {
                    height: 100px;
                }

                .swiper-slide {
                    border-radius: 6px;
                    opacity: 0.6;

                    .lot-swiper-img {
                        width: 100%;
                        height: 100%;
                        border-radius: 6px;

                        img {
                            border-radius: 6px;
                        }
                    }
                }

                .swiper-slide-thumb-active {
                    opacity: 1;
                    border: 2px solid #fa5a0a;
                }
            }

            .mySwiper2 {
                height: 100%;

                .swiper-button-prev,
                .swiper-button-next {
                    &::after {
                        min-width: 24px;
                        height: 24px;
                        font-size: 16px;
                        color: #fa5a0a;
                        background-color: white;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 3px;

                        @media (min-width: 768px) {
                            min-width: 32px;
                            height: 32px;
                            border-radius: 6px;
                            font-size: 18px;
                        }
                    }
                }

                .swiper-button-prev {
                    left: 10px;

                    @media (min-width: 768px) {
                        left: 20px;
                    }

                    &::after {
                        padding-right: 0;

                        @media (min-width: 768px) {
                            padding-right: 4px;
                        }
                    }
                }

                .swiper-button-next {
                    right: 10px;

                    @media (min-width: 768px) {
                        right: 20px;
                    }

                    &::after {
                        padding-left: 0;

                        @media (min-width: 768px) {
                            padding-left: 4px;
                        }
                    }
                }

                .swiper-slide {
                    border-radius: 12px;
                    background: transparent;

                    img {
                        border-radius: 12px;
                    }
                }
            }

            button {
                z-index: 9999;

                svg {
                    width: 20px;
                    height: 20px;

                    @media (min-width: 576px) {
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }
    }
}
.lot-slider .slick-slide {
    max-width: 33.33% !important; /* Ensure the width is enforced for one-third */
    flex: 0 0 auto; /* Prevent flexbox from resizing */
}