
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.text-poppins{
    font-family: "Roboto", sans-serif;
}
@font-face {
    font-family: 'SF Pro Display';
    src: url('SFProDisplay-Medium.woff2') format('woff2'), url('SFProDisplay-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('SFProDisplay-Bold.woff2') format('woff2'), url('SFProDisplay-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('SFProDisplay-Regular.woff2') format('woff2'), url('SFProDisplay-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* helvetica */

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('HelveticaNeueCyr/HelveticaNeueCyr-Thin.woff2') format('woff2'), url('HelveticaNeueCyr/HelveticaNeueCyr-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('HelveticaNeueCyr/HelveticaNeueCyr-UltraLight.woff2') format('woff2'),
        url('HelveticaNeueCyr/HelveticaNeueCyr-UltraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('HelveticaNeueCyr/HelveticaNeueCyr-Light.woff2') format('woff2'), url('HelveticaNeueCyr/HelveticaNeueCyr-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('HelveticaNeueCyr/HelveticaNeueCyr-Roman.woff2') format('woff2'), url('HelveticaNeueCyr/HelveticaNeueCyr-Roman.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('HelveticaNeueCyr/HelveticaNeueCyr-Medium.woff2') format('woff2'), url('HelveticaNeueCyr/HelveticaNeueCyr-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('HelveticaNeueCyr/HelveticaNeueCyr-Bold.woff2') format('woff2'), url('HelveticaNeueCyr/HelveticaNeueCyr-Bold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
