@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .empire__sidebar_link {
        @apply text-grayText w-full flex items-center py-2.5 pl-4 text-sm hover:bg-hoverNav hover:text-primary relative cursor-pointer;
    }

    .active-nav {
        @apply text-primary bg-hoverNav after:absolute after:w-[2px] after:h-full after:left-0 after:bg-primary after:rounded-e;
    }

    .link_icon {
        @apply w-5 h-5 text-grayText transition duration-75;
    }

    .active_icon {
        @apply text-primary;
    }

    .form_control {
        @apply text-black border-none rounded-full px-5 py-4 bg-inputBg w-full h-14 focus:outline-none focus:ring-0;
    }

    .form_control_textarea {
        @apply text-black border-none px-5 py-4 bg-inputBg w-full focus:outline-none focus:ring-0 rounded-2xl;
    }

    .form_control_p2 {
        @apply text-black border text-sm border-solid border-gray200 rounded-[10px] px-5 py-4 bg-white w-full h-12 focus:ring-1 focus:ring-blue-700;
    }

    .form_label {
        @apply text-black text-sm inline-block mb-2 font-medium;
    }

    .step_completed {
        @apply text-primary after:content-[''] after:w-full after:border-b after:border-primary after:border-2 after:inline-block;
    }

    .step_inprogress {
        @apply text-primary after:content-[''] after:w-full after:border-b after:border-2 after:inline-block;
    }

    .step_normal {
        @apply text-grayText after:content-[''] after:w-full after:border-b after:border-gray-200 after:border-2 after:inline-block;
    }

    .stepbar {
        @apply flex w-full items-center relative;
    }

    .step_last {
        @apply text-grayText w-auto;
    }

    .btn_primary {
        @apply bg-primary px-3 py-3 rounded-full border border-primary text-sm font-semibold text-white hover:bg-primary600;
    }

    .btn_outline_primary {
        @apply outline-1 outline-primary outline bg-white transition-all delay-100 px-3 py-3 rounded-full text-sm font-semibold text-primary hover:bg-primary hover:text-white;
    }

    .row_active {
        @apply bg-grayLight;
    }

    input[id^='react-select-'] {
        @apply focus:outline-none focus:border-transparent focus:ring-0;
    }

    .hover_label {
        @apply transition-all ease-in-out delay-500 relative after:hover:w-full after:hover:h-full after:hover:content-[''] after:hover:absolute after:hover:bg-black after:hover:bg-opacity-25 after:hover:rounded-xl after:hover:top-0;
    }

    .tab_button {
        @apply h-full;
    }

    .tab_button>div {
        @apply h-auto;
    }

    .tab_button>button {
        min-width: 200px;
        @apply text-grayText text-sm focus:ring-0 focus:ring-transparent;
    }

    .tab_button>button.active {
        @apply focus:ring-0 focus:ring-transparent focus:shadow-none focus-within:ring-transparent border-primary text-black;
    }

    .accordion_pricing>button {
        @apply bg-transparent focus:ring-0 focus:ring-transparent focus:shadow-none hover:bg-transparent p-0 focus:bg-transparent;
    }

    input[type='checkbox'] {
        @apply bg-white ring-0 focus:ring-0 focus:ring-transparent;
    }

    input[type='checkbox']:checked {
        @apply bg-white ring-0 focus:ring-0 focus:ring-transparent;
    }

    .rflb_text_title {
        @apply text-sm font-semibold text-grayText;
    }

    .tab_Rfqlb>button.active {
        @apply border bg-white text-primary;
    }

    .tab_Rfqlb>button {
        min-width: 200px;
        @apply text-black text-sm focus:ring-0 focus:ring-transparent font-bold;
    }
    .modal_backdrop{
        @apply bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40;
    }
    .modal_content{
        @apply fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-full flex items-center justify-center;
    }
}