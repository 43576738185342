@import './variables';
@import '../fonts/fonts.css';
@import '../../components/DateSelect/DateSelect.scss';
html,
body {
    color: $text-dark;
    font-family: 'HelveticaNeueCyr', sans-serif;
    font-weight: 500;
    margin: 0;
    padding: 0 !important;
    line-height: 140%;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100%;
}

.login-form {
    input {
        font-family: sans-serif;

        &:focus-visible {
            outline: none;
        }
        &:focus {
            background-color: #fff9f5;
        }
    }
    input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px #fff9f5 inset;
    }
}
input {
    font-family: sans-serif;
}
@media (min-width: 768px) {
    #app_layout {
        height: 100vh;
    }
}
.logout-icon {
    fill: #ff4d54;
    &:hover {
        fill: #8a0005;
    }
}

.carriers-icon {
    fill: $text-light;
}
.empire__sidebar_link {
    transition: all 0.3s ease;
    &:hover {
        .link_icon {
            color: $primary;
        }
        .carriers-icon {
            fill: $primary;
        }
    }
    &.active-nav {
        .carriers-icon {
            fill: $primary;
        }
    }
}
.main__layout_height {
    min-height: calc(100vh - 64px);
}
#drawer-navigation {
    height: calc(100vh - 64px);
}
.empire_select__control {
    height: 56px;
}
.select_bg_none {
    .empire_select__control {
        cursor: pointer;
        background-color: transparent;
        height: 20px;
        min-height: unset;
        padding: 0;
    }
    .empire_select__value-container {
        padding: 0;
        margin: 0;
        font-size: 14px;
    }
    .empire_select__input-container {
        padding: 0;
        margin: 0;
    }
    .empire_select__indicator {
        padding-top: 0;
        padding-bottom: 0;
    }
    .empire_select__indicator-separator {
        margin: 0;
    }
    .empire_select__menu {
        width: 11rem;
        left: -21px;
        margin-top: 12px;
    }
    .empire_select__single-value {
        overflow: visible;
    }
}
.empire_select__menu {
    box-shadow: 0px 6px 20px 0px rgba(146, 140, 151, 0.25) !important;
    border-radius: 8px;
    color: $text-dark;
    border: none;
    .empire_select__menu-list {
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
        }
    }
    .empire_select__option {
        cursor: pointer;
        padding: 10px 12px;
        &--is-focused {
            background-color: #eff1f5;
        }
        &--is-selected {
            background-color: $primary;
        }
    }
}
.react-datepicker-wrapper {
    width: 100%;
}
.react-datepicker-popper .react-datepicker{
    .react-datepicker__day--keyboard-selected.react-datepicker__day--today,
    .react-datepicker__day--today{
        background-color: #fa5a0a !important;
        color: white !important;
        border-radius: 0.3rem;
    }
     .react-datepicker__day--selected,.react-datepicker__day--keyboard-selected {
        background-color: #ff945e !important;
        color: white !important;
     }
}
.react-datepicker-popper {
    .react-datepicker {
        border: none;
        border-radius: 8px;
        background: #fff;
        box-shadow: 0px 5.3333330154418945px 15.999999046325684px 0px rgba(146, 140, 151, 0.25);
        .react-datepicker__triangle {
            &::after {
                content: none !important;
            }
            &::before {
                content: none !important;
            }
        }
        .react-datepicker__header {
            background-color: transparent;
            &:not(.react-datepicker__header--has-time-select) {
                border-bottom: none;
            }
            .react-datepicker__current {
                &-month {
                    margin: 1rem 0;
                }
            }
        }
        .react-datepicker__navigation {
            top: 18px;
            border: 1px solid #f1f1f1;
            height: 38px;
            width: 38px;
            margin: 0 1rem;
            border-radius: 8px;
            .react-datepicker__navigation-icon {
                &--next {
                    left: 0;
                    top: 0;
                }
                &--previous {
                    left: 26px;
                    &::before {
                        right: 20px;
                    }
                }
                &::before {
                    border-width: 2px 2px 0px 0;
                    border-color: $text-light;
                    border-radius: 1px;
                }
            }
        }
        .react-datepicker__day-names {
            .react-datepicker__day-name {
                color: $text-light;
                font-size: 12px;
                width: 2.75rem;
                margin: 0;
                padding: 0.5rem 0.75rem;
            }
        }
        .react-datepicker__day {
            font-size: 12px;
            width: 2.75rem;
            margin: 0;
            padding: 0.25rem 0.75rem;
            &--keyboard-selected {
                background-color: $primary;
                color: #fff;
            }
            &--selected {
                background-color: $primary;
                color: #fff;
            }
        }
        .react-datepicker__month {
            margin: 1rem;
        }
    }
}

.switcher {
    display: inline-block;
    padding: 4px;
    background: #fff;
    border-radius: 2px;
    width: 280px;
    border-radius: 30px;
    border: solid 1px $primary;
    background-color: #f4f4f4;
    position: relative;
    .switcher__input {
        display: none;
        &:checked + .switcher__label {
            color: #fff;
        }
        &--yang:checked ~ .switcher__toggle {
            left: 137px;
        }
    }

    .switcher__label {
        float: left;
        width: 50%;
        font-size: 12px;
        line-height: 30px;
        color: $primary;
        font-weight: 600;
        text-align: center;
        cursor: pointer;
        position: inherit;
        z-index: 10;
        transition: color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
        will-change: transform;
        .w-fit {
            width: 100%;
        }
    }

    .switcher__toggle {
        position: absolute;
        float: left;
        width: 50%;
        font-size: 14px;
        cursor: pointer;
        background-color: $primary;
        border-radius: 30px;
        left: 2px;
        padding: 18px;
        top: 1px;
        transition: left 0.25s cubic-bezier(0.4, 0, 0.2, 1);
        will-change: transform;
    }
}
.switch-sm {
    width: 180px !important;
    margin-bottom: 1rem;
    .switcher__input--yang:checked ~ .switcher__toggle {
        left: 87px;
    }
}
.dimention_card_gray {
    select,
    input {
        padding: 0;
        border: 0;
        &:focus {
            outline: none;
            border-color: transparent;
        }
    }
}
.empire_accordion {
    button {
        > svg {
            color: $primary;
            rotate: unset;
            transform: unset;
        }
    }
}
.drawer_body {
    @media (min-width: 1440px) {
        height: calc(100vh - 90px);
    }
}
.drawer_scroll_remove {
    height: calc(100vh - 124px);
}
.dimension_input {
    margin-bottom: 1rem;
    width: 165px;
    @media (min-width: 1740px) {
        width: 10.7%;
    }
}
.block_ellipsis {
    display: block;
    display: -webkit-box;
    max-width: 100%;
    height: 38px;
    margin: 0 auto;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.border-t-0 {
    border-top-width: 0 !important;
}
.CircularProgressbar {
    .CircularProgressbar-path {
        stroke: $primary;
    }
    .CircularProgressbar-trail {
        stroke: #e8ecf9;
    }
    .CircularProgressbar-text {
        fill: $text-dark;
        font-size: 6px;
    }
}
.pac-container {
    border-radius: 8px;
    background-color: #fff;
    border: none;
    margin-top: 1rem;
    box-shadow: 0px 5.33px 15.99px 0px rgba(146, 140, 151, 0.25);
    position: absolute !important;
    overflow-y: auto;

    // / Define a CSS variable for top position /
    --container-top: 620px;

    // / Media queries for different screen sizes /
    @media (max-width: 768px) {
        --container-top: 520px;
    }

    @media (max-width: 480px) {
        --container-top: 420px;
    }

    // / Apply the dynamic top value /
    top: var(--container-top);
}

.pac-item {
    padding: 6px 8px;
    border: none;
    color: #98a7c3;
    font-size: 14px;
    &:hover {
        background-color: #f1f4f8;
    }
}

.pac-container::after {
    content: unset;
}
// toggle switch type money or percent
.toggle_button {
    display: inline-block;
    padding: 10px 16px;
    position: relative;
    text-align: center;
    transition: background 600ms ease, color 600ms ease;
}

input[type='radio'].toggle_icons {
    display: none;
    & + label {
        cursor: pointer;
        color: #98a7c3;
        &:after {
            border-radius: 32px;
            background: #fff;
            box-shadow: 0px 2px 33px 0px rgba(0, 0, 0, 0.05);
            content: '';
            height: 100%;
            position: absolute;
            top: 0;
            transition: left 200ms cubic-bezier(0.77, 0, 0.175, 1);
            width: 100%;
            z-index: -1;
        }
    }
    &.toggle-left + label {
        border-right: 0;
        &:after {
            left: 100%;
        }
    }
    &.toggle-right + label {
        margin-left: -2px;
        &:after {
            left: -93%;
        }
    }
    &:checked + label {
        cursor: default;
        color: #1f293c;
        transition: color 200ms;
        &:after {
            left: 0;
        }
    }
}
// loader

.loader_spinner {
    border-top-color: $primary;
    -webkit-animation: spinner 1.5s linear infinite;
    animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
input[type='checkbox'] {
    cursor: pointer;
    &:checked {
        border: 2px solid $primary;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAMCAYAAABvEu28AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADdSURBVHgBndA9DoIwFAfw1xKMiQuDEEePoKMxJsAFjCcQTwKeRG7ADYCEKCtHYDS64FojtRVD+BD5+E99r+0vr0UwMLLqLRAeOXxNUqIJMCAZInoAaMZKSUDogaBnCoj0aVCIMcVaL6gJufqrGPNa0UNT1s/GP2SqBmoTwkvEATbWKdsD++6uD/VJwj3CqZ03KggPLl5goKHoF0dSPakP8r3LnxZYzDQL3Yi8iCbCeNsFyaGfGAA/PO+ClKAGrBPCU/qjm7uxANJjBYnakNpE1ckopf6TTnaJv0ygJW9Nmm/cKRyYdwAAAABJRU5ErkJggg==');
        background-size: 0.75em 0.55em;
    }
}

.ql-container {
    min-height: 10rem;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.ql-editor {
    height: 100%;
    min-height: 34.3rem;
    flex: 1;
    overflow-y: auto;
    width: 100%;
}
.top_right_radius {
    border-top-right-radius: 6px;
}
.card_shadow {
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
}
.footer_shadow {
    box-shadow: 0px -1px 10px rgba(160, 160, 160, 0.1);
}

li[aria-current='page'] {
    --tw-bg-opacity: 1;
    --tw-text-opacity: 1;
    background-color: rgb(235 245 255 / var(--tw-bg-opacity));
    color: rgb(28 100 242 / var(--tw-text-opacity));
}
.service-price {
    th:last-of-type {
        text-align: right;
    }
}
[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: none;
    --tw-ring-color: 0px !important;
    --tw-ring-offset-shadow: none;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: none;
    border-color: rgb(221, 217, 217) !important;
}
::-webkit-scrollbar-track {
    max-height: 50%;
    height: 50%;
}

::-webkit-scrollbar-thumb {
    background: #d9d9d9;
}
::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}

.dropdown__control {
    cursor: pointer;

    .dropdown__value-container {
        max-height: 36px;
        cursor: pointer;

        div {
            &:nth-child(1) {
                border: none;
            }
        }

        .dropdown__multi-value {
            border-radius: 30px;

            .dropdown__multi-value__label {
                padding-top: 4px;
                line-height: 16px;
                border-radius: 15px 0 0 15px;
            }

            .dropdown__multi-value__remove {
                border-radius: 0 15px 15px 0;

                &:hover {
                    background-color: #ffefe7;
                    color: #2e2624;
                }
            }
        }
    }
}
.dropdown__menu {
    cursor: pointer;

    .dropdown__option {
        border: none;
        cursor: pointer;

        &:hover {
            background-color: $secondary;
        }
        &:focus {
            background-color: $secondary;
        }
        &:focus-visible {
            background-color: $secondary;
        }
        &:target {
            background-color: $secondary;
        }
        &:active {
            background-color: $secondary;
        }

        input {
            border: 2px solid #b9b7b6;
            border-radius: 3px;
            width: 16px;
            height: 16px;
            cursor: pointer;

            @media (min-width: 576px) {
                width: 18px;
                height: 18px;
            }
        }

        input[type='checkbox'] {
            border: 2px solid #b9b7b6;
            border-radius: 3px;
            width: 16px;
            height: 16px;
            cursor: pointer;

            @media (min-width: 576px) {
                width: 18px;
                height: 18px;
            }
        }
        input[type='checkbox']:checked {
            background-color: #fa5a0a;
            color: $secondary;
            background-image: url('../img/checkmark.png');
            border: none;
            cursor: pointer;
        }
    }

    .dropdown__option--is-focused {
        background-color: $secondary;
    }
}

.toggle-active {
    background-color: white;

    img {
        filter: brightness(0) saturate(100%) invert(59%) sepia(83%) saturate(5215%) hue-rotate(354deg) brightness(101%) contrast(106%);
    }
    svg {
        color: #fa5a0a;
    }
}

.table-detail {
    border: 1px solid #eae9e9;
    border-radius: 0 !important;

    .rdt_TableHead {
        .rdt_TableHeadRow {
            background-color: #f9f9f9;

            .rdt_TableCol_Sortable {
                font-size: 12px;
                font-weight: 400;
                color: #746e6d;
            }
        }
    }

    .rdt_TableBody {
        .rdt_TableRow {
            div {
                div {
                    overflow: visible;
                }
            }
            cursor: pointer;
            .rdt_TableCell {
                font-size: 14px;
                font-weight: 400;
                color: #2e2624;
                min-width: fit-content;
            }
        }
    }
}

.rdt_Pagination {
    div {
        &:nth-child(4) {
            margin-left: auto;

            @media (min-width: 480px) {
                margin-left: unset;
            }
        }
    }

    select:not([size]) {
        background-image: none;
        padding: 0;
    }
    select {
        line-height: 13px;
        padding-right: 25px !important;
    }

    #pagination-first-page,
    #pagination-previous-page,
    #pagination-next-page,
    #pagination-last-page {
        fill: #fa5a0a;
    }

    button:disabled {
        opacity: 0.4;
    }
}

.phase-btn {
    span {
        padding: 0;
    }
}

.lot-slider {
    .slick-slider {
        .slick-arrow {
            width: 24px;
            height: 24px;
            border: 1px solid #fa5a0a;
            border-radius: 4px;
            z-index: 999;
            background-color: white;
            display: flex !important;
            justify-content: center;
            align-items: center;
            top: 42%;

            @media (min-width: 1360px) {
                width: 30px;
                height: 30px;
                border-radius: 6px;
            }
        }

        .slick-prev {
            left: -6px;

            @media (min-width: 1360px) {
                left: -14px;
            }

            &::before {
                content: '';
                display: block;
                background-image: url('../img/lotarrow-left.svg');
                background-size: 100% 100%;
                width: 18px;
                height: 18px;

                @media (min-width: 1360px) {
                    width: 24px;
                    height: 24px;
                }
            }
        }

        .slick-next {
            right: -6px;

            @media (min-width: 1360px) {
                right: -10px;
            }

            &::before {
                content: '';
                display: block;
                background-image: url('../img/lotarrow-right.svg');
                background-size: 100% 100%;
                width: 18px;
                height: 18px;

                @media (min-width: 1360px) {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
}

.topbar__control {
    border-radius: 6px 0 0 6px !important;
    padding: 0;
    border-color: #d8d8d8 !important;
    z-index: 9999 !important;

    .topbar__indicators {
        display: none;
    }

    .topbar__placeholder {
        color: #b9b7b6;
        font-size: 12px;
        font-family: sans-serif !important;

        @media (min-width: 480px) {
            font-size: 14px;
        }
    }

    .topbar__input-container {
        cursor: pointer;

        input {
            margin-top: 1px !important;
            font-family: sans-serif !important;
            cursor: pointer;
        }
    }
}

.topbar__menu {
    z-index: 99999999 !important;
    position: relative;
    display: block;
    padding: 0;
    margin: 0;
    border: none !important;
    width: 310px !important;

    @media (min-width: 480px) {
        width: 100% !important;
    }

    .topbar__option {
        border: none !important;
        padding: 14px;
        z-index: 99999999 !important;
        position: relative;
        display: block;
        cursor: pointer;

        &:hover {
            background-color: $secondary;
        }
        &:focus {
            background-color: $secondary;
        }
        &:focus-visible {
            background-color: $secondary;
        }
        &:target {
            background-color: $secondary;
        }
        &:active {
            background-color: $secondary;
        }
    }

    .topbar__option--is-focused {
        background-color: $secondary;
    }
}

.phase-card-active {
    border-color: #fa5a0a !important;

    .card-head {
        background-color: #ffefe7;
        border-color: #fa5a0a;
    }

    .card-body {
        background-color: #fff9f5;
        height: calc(100% - 53px);
    }

    .border-t {
        border-color: #fa5a0a;
    }
}

.table-active {
    .rdt_TableBody {
        .rdt_TableRow {
            background-color: #fff9f5;
            border-left: 3px solid #fa5a0a;
        }
    }
}

.map-detail {
    .gmnoprint {
        div {
            background-color: transparent !important;

            .gm-control-active {
                border: 1px solid #fa5a0a !important;
                background-color: white !important;
                border-radius: 0 0 6px 6px;

                &:first-child {
                    border-bottom: none !important;
                    border-radius: 6px 6px 0 0;
                }

                img {
                    width: 14px !important;
                    height: 14px !important;
                    filter: brightness(0) saturate(100%) invert(49%) sepia(42%) saturate(6470%) hue-rotate(356deg) brightness(98%) contrast(99%);

                    @media (min-width: 576px) {
                        width: 16px !important;
                        height: 16px !important;
                    }
                }
            }
        }

        .gm-style-mtc {
            button {
                height: 34px !important;
                font-size: 14px !important;

                @media (min-width: 576px) {
                    height: 40px !important;
                    font-size: 16px !important;
                }
            }
        }
    }

    .gm-fullscreen-control {
        width: 34px !important;
        height: 34px !important;

        @media (min-width: 576px) {
            width: 40px !important;
            height: 40px !important;
        }

        img {
            width: 16px !important;
            height: 16px !important;

            @media (min-width: 576px) {
                width: 18px !important;
                height: 18px !important;
            }
        }
    }
}

.dark {
    .react-datepicker{
        background-color:#333 !important;
        color:#746E6D;
    }
    .react-datepicker-popper .react-datepicker .react-datepicker__day--selected,
    .react-datepicker-popper .react-datepicker .react-datepicker__day--keyboard-selected {
        background-color: #ff945e !important;
        color: white !important;
    }
    .react-datepicker-popper .react-datepicker .react-datepicker__day--today,
    .react-datepicker-popper .react-datepicker .react-datepicker__day--keyboard-selected.react-datepicker__day--today{
       background-color: #fa5a0a !important;
       border-radius: 0.3rem;
    }
    .react-datepicker-popper .react-datepicker .react-datepicker__day-names .react-datepicker__day-name{
    color: white;
    }
    .react-datepicker-popper .react-datepicker .react-datepicker__header .react-datepicker__current-month{
        color: white;
    }
    .react-datepicker-popper .react-datepicker{
        box-shadow: none;
    }
    .react-datepicker-popper .react-datepicker .react-datepicker__day{
        color:#746E6D;
       
    }
    background-color: #0d0d0d;

    ::-webkit-scrollbar-thumb {
        background-color: #4d4d4d;
    }

    .logo-dark {
        img {
            display: none;
        }

        div {
            background-image: url('../img/empire-logo-dark.svg');
            background-size: 100% 100%;
            width: 100px;
            height: 39px;

            @media (min-width: 576px) {
                width: 142px;
                height: 56px;
            }
        }
    }

    .phase-card-active {
        .card-head {
            background-color: #a73c07;
        }

        .card-body {
            background-color: #541e03;
        }
    }

    .toggle-active {
        background-color: #222;

        img {
            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(4891%) hue-rotate(5deg) brightness(112%) contrast(98%);
        }
    }

    .arrow-down {
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(96deg) brightness(105%) contrast(106%);
    }

    .dropdown__menu {
        background-color: #252525;

        .dropdown__option {
            border: none;
            color: #b9b7b6;
            cursor: pointer;

            &:hover {
                background-color: #4d4d4d;
            }
            &:focus {
                background-color: #4d4d4d;
            }
            &:focus-visible {
                background-color: #4d4d4d;
            }
            &:target {
                background-color: #4d4d4d;
            }
            &:active {
                background-color: #4d4d4d;
            }

            input {
                border: 2px solid #b9b7b6;
                background-color: #252525;
            }

            input[type='checkbox'] {
                border: 2px solid #b9b7b6;
            }
            input[type='checkbox']:checked {
                background-color: #fa5a0a;
                border-color: #fa5a0a;
            }
        }

        .dropdown__option--is-focused {
            background-color: #4d4d4d;
        }
    }

    .dropdown__control {
        background-color: #252525;
        border-color: #746e6d;

        .dropdown__value-container {
            div {
                &:first-child {
                    border: none !important;
                }
            }
            .dropdown__input-container {
                input {
                    color: white !important;
                }
            }

            &:focus-visible {
                border: none;
                outline: none;
            }

            .dropdown__multi-value {
                background-color: #4d4d4d;

                .dropdown__multi-value__label {
                    background-color: #4d4d4d;
                    color: white;
                }

                .dropdown__multi-value__remove {
                    background-color: #4d4d4d;
                    color: white;
                }
            }
        }

        .dropdown__indicator-separator {
            background-color: #746e6d;
        }

        .dropdown__indicator {
            color: #746e6d;
        }
    }

    .topbar__control {
        background-color: #252525;
        border-color: #746e6d !important;

        .topbar__value-container {
            border-radius: 6px 0 0 6px;
            background-color: transparent;

            .topbar__placeholder {
                color: #746e6d;
                background-color: #252525;
            }

            .topbar__input-container {
                background-color: transparent;

                .topbar__input {
                    color: white !important;
                    background-color: transparent !important;
                }
            }

            .topbar__single-value {
                color: white;
                z-index: 999;
            }
        }
    }

    .topbar__menu {
        background-color: #252525;

        .topbar__option {
            color: #b9b7b6;

            &:hover {
                background-color: #4d4d4d;
            }
            &:focus {
                background-color: #4d4d4d;
            }
            &:focus-visible {
                background-color: #4d4d4d;
            }
            &:target {
                background-color: #4d4d4d;
            }
            &:active {
                background-color: #4d4d4d;
            }

            p {
                color: #b9b7b6;
            }
        }

        .topbar__option--is-focused {
            background-color: #4d4d4d;

            p {
                color: #b9b7b6;
            }
        }
        .topbar__option--is-selected {
            background-color: #4d4d4d;
        }
    }

    .table-detail {
        border-color: #393939;

        .rdt_Table {
            background-color: #252525;
        }
        .rdt_TableHeadRow {
            background-color: #393939;

            .rdt_TableCol_Sortable {
                color: white;
            }
        }

        .rdt_TableRow {
            background-color: #252525;

            .rdt_TableCell {
                color: white;
            }
        }
    }

    .table-active {
        .rdt_TableRow {
            background-color: #541e03;
        }
    }

    .rdt_Pagination {
        background-color: #252525;
        color: white;
    }

    .card-img {
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7494%) hue-rotate(345deg) brightness(99%) contrast(105%);
    }

    .slick-arrow {
        background-color: #252525 !important;
        border-color: #b9b7b6 !important;

        &::before {
            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7494%) hue-rotate(345deg) brightness(99%) contrast(105%);
        }
    }

    .gmnoprint {
        .gm-style-mtc {
            button {
                background-color: #252525 !important;
                color: white !important;
            }

            &:first-child {
                border-right: 1px solid #535353;
            }
        }

        div {
            .gm-control-active {
                background-color: #252525 !important;
                &:last-child {
                    top: -1px !important;
                }
            }
        }
    }

    .gm-fullscreen-control {
        background-color: #252525 !important;

        img {
            filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
        }
    }

    .gm-svpc {
        background-color: #252525 !important;
    }
}

.card-pagination {
    align-items: center;

    .page-item-previous {
        margin-right: 10px;
        a {
            color: #fa5a0a;
        }
    }

    .page-item {
        padding: 5px 11px;
        border: 1px solid #eae9e9;
        border-radius: 4px;
        margin-right: 4px;
        color: #746e6d;

        a {
            font-size: 14px;
            font-weight: 400;
        }
    }

    .active {
        color: #fa5a0a;
        border-color: #fa5a0a;
    }

    .page-item-next {
        margin-left: 6px;

        a {
            color: #fa5a0a;
        }
    }
}

.dark .card-icon {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(82deg) brightness(112%) contrast(111%);
}

.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
